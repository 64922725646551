<template>
  <tr :class="{item__disabled: !props.item.active}">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" style="" >
      {{props.item.id}}
    </td>
    <td class="justify-center" style="" >
      {{props.item.name}}
    </td>
    <td class="justify-center" style="" >
      {{props.item.unit.code}}
    </td>
    <td class="justify-center" style="" >
      <span v-if="props.item.amount_sale">
        {{props.item.amount_sale | money}}
      </span>
      <span v-else>
        Não definido
      </span>
    </td>
    <td class="justify-center" style="padding-top: 4px; ">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="openItemCodes()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>code</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Códigos</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="generateItemCodes()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>code</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Gerar Códigos</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.files(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>image</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Imagens</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Items',
    data () {
      return {
        wiDialogs: {
          files: (item) => {
            return {
              title: 'Imagens do ' + this.item.title,
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: this.item.name + '-fileList',
                  api: {
                    url: 'register/' + this.item.name + '/file',
                    params: '?order=id,desc&where=item_id,' + item.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Imagem',
                action: () => {
                  this.$WiDialog({
                    title: 'Imagens do ' + this.item.title,
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        item_id: item.id
                      }
                    },
                    onClose: () => {
                      this.$WiView(this.item.name + '-fileList').refresh()
                    }
                  })
                }
              }
            }
          }
        },
        item: {
          name: 'product',
          title: 'Produto'
        }
      }
    },
    methods: {
      openItemCodes: function () {
        this.$WiListDialog({
          wiConfig: 'product-code',
          filter: {
            item_id: this.props.item.id
          },
          wiEditData: {
            item_id: this.props.item.id,
            item: this.props.item
          }
        })
      },
      generateItemCodes: function () {
        this.$WiEditDialog({
          wiConfig: 'product-code-generate',
          data: {
            item_id: this.props.item.id,
            item: this.props.item,
            quantity: 8
          },
          onSubmit: ({ data }) => {
            this.$WiEditDialog({
              wiConfig: 'product-code-print',
              data: {
                item_id: this.props.item.id,
                item: this.props.item,
                codes: data.codes,
                layout: data.html
              }
            })
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.item__disabled {
  color: #f44336;
  color: grey; text-decoration: line-through;
}
</style>